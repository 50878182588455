import html2canvas from "html2canvas";
import moment from "moment";

import { CHARGE_BALANCE_THRESHOLD, CHEMICALS_SYMBOL_MAPPING, DATE_FORMAT } from "@constants/global.constants";

import DupontLogger from "./DupontLogger";
import { DateString } from "./StringConstants";

const featureIdToField = {
  1: "mCIP",
  2: "CEB",
};

export const getDisabledUFTech = ufSpecialFeatureID => featureIdToField[ufSpecialFeatureID] || "";

export const convertUpto2Digits = value => (isNaN(parseFloat(value)) ? "0" : parseFloat(value).toFixed(2));

export const convertUptoDigits = (value, digits = 2) => {
  const tempValue = isNaN(value) ? 0 : parseFloat(value);
  return (tempValue && tempValue.toFixed(digits)) || 0;
};

/**
 * Changes the format of a chemical symbol based on a predefined mapping.
 * Replace each occurrence of the 'from' symbol with the 'to' symbol
 *
 * @param {string} chemicalSymbol - The chemical symbol to be formatted.
 * @return {string} - The formatted chemical symbol.
 */
export const changeChemicalFormat = chemicalSymbol => {
  if (!chemicalSymbol) return "0";

  for (const [from, to] of Object.entries(CHEMICALS_SYMBOL_MAPPING)) {
    chemicalSymbol = `${chemicalSymbol}`.replaceAll(from, to);
  }

  return chemicalSymbol;
};

/**
 * Converts a source object into a new object with keys appended by a type.
 * @param {Object} source - The source object.
 * @param {string} type - The type to append to each key.
 * @return {Object} - The new object with keys appended by the type.
 */
export const getChemicalObject = (source, type, disabledField) =>
  Object.entries(source).reduce((result, [key, value]) => {
    const paramValue = disabledField && key.includes(disabledField) ? "0" : value;
    result[`${key}_${type}`] = paramValue;
    return result;
  }, {});

export const getFolderName = foldername =>
  foldername?.length > 20 ? `${foldername?.substring(0, 20)}...` : foldername;

export const debounce = (callback, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

const validData = (data, label, flag) => {
  const den = flag ? "0" : 0;
  return data ? data[label] : den;
};

export const formatChemicalSymbol = (chemicalSymbol, flag, field = "symbol") =>
  changeChemicalFormat(validData(chemicalSymbol, field, flag)).toString();

export const calculateSum = arr => {
  const sum = arr.reduce((acc, val) => {
    const value = parseFloat(val) || 0;
    return acc + value;
  }, 0);
  return sum;
};

/**
 * Calculates the difference between two dates.
 *
 * @param {string|Date|moment.Moment} date1 - The first date.
 * @param {string|Date|moment.Moment} date2 - The second date.
 * @returns {number} The difference between the two dates in milliseconds.
 */
export const getDateDiff = (date1, date2) => moment(date1).diff(moment(date2));

/**
 * Creates a comparator function for sorting objects based on a specified key, type, and order.
 *
 * @param {Object} options - The options for the comparator.
 * @param {string} options.key - The key of the object to sort by.
 * @param {string} options.type - The type of the value to sort ("string" or "date").
 * @param {string} [options.order="asc"] - The order of sorting ("asc" for ascending or "desc" for descending).
 * @param {boolean} [options.useNumeric] - Whether to use numeric collation for string comparison.
 * @returns {Function} A comparator function that can be used in array sort method.
 */
export const sortComparator =
  ({ key, type, order = "asc", useNumeric, aliasKeys }) =>
  (a, b) => {
    const getValue = obj => {
      if (obj[key] === undefined && isIterable(aliasKeys)) {
        for (const aliaskey of aliasKeys) {
          if (obj[aliaskey] !== undefined) {
            return obj[aliaskey];
          }
        }
      }
      return obj[key];
    };

    let valA = getValue(a, key, aliasKeys);
    let valB = getValue(b, key, aliasKeys);

    if (order === "desc") {
      // swapping the variables for desc order
      [valA, valB] = [valB, valA];
    }
    if (type === "string") {
      return valA?.localeCompare(valB, undefined, { numeric: useNumeric });
    } else if (type === "date") {
      // date should be in ISO Format
      return getDateDiff(valA, valB);
    }
    return valA - valB;
  };

// function returns true if all doses are disabled or total dose value is 0
export const isDoseErr = ({ indicators, doseValues }) => {
  const isAllDosesDisabled = indicators.every(indicator => !indicator);
  const totalDoseValue = calculateSum(doseValues);
  return isAllDosesDisabled || totalDoseValue === 0;
};

export const loadExternalScript = url => {
  if (url) {
    const script = document.createElement("script");
    script.src = url;
    document.body.appendChild(script);
  }
};

export const isValueInRange = (value, ranges) => {
  if (isNaN(value) || !ranges) return false;
  const { minValue, maxValue } = ranges;

  const val = parseFloat(value);
  return val >= minValue && val <= maxValue;
};

export const groupBy = (array, key) => {
  if (!array || !key) return {};
  return array.reduce((acc, currentValue) => {
    const groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};

/**
 * Converts an array of objects into an object, using a specified key from each object as the key for the new object.
 *
 * @param {Array} array - The array of objects to convert.
 * @param {string} key - The key to use for the new object.
 * @returns {Object} - The resulting object with keys derived from the specified key in each object.
 */
export const convertListToObj = (array, key) => {
  if (!array || !key) return {};
  return array.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {});
};

/**
 * Clones an array and overrides specific objects within it based on provided overrides.
 *
 * @param {Array<Object>} array - The original array to be cloned and modified.
 * @param {Object} overrides - An object containing keys that match the `arrayObjkey` values in the array and values that are objects with properties to override.
 * @param {string} arrayObjkey - The key in the array objects that will be used to match against the keys in the overrides object.
 * @returns {Array<Object>} - A new array with the specified overrides applied.
 */
export const cloneArrayAndOverride = (array, overrides, arrayObjkey) => {
  const clonedArray = structuredClone(array);
  Object.entries(overrides).forEach(([overrideValueKey, OverrideValueData]) => {
    const arrayOverrideIndex = clonedArray.findIndex(obj => obj[arrayObjkey] === overrideValueKey);
    if (arrayOverrideIndex !== -1) {
      clonedArray[arrayOverrideIndex] = {
        ...clonedArray[arrayOverrideIndex],
        ...OverrideValueData,
      };
    }
  });
  return clonedArray;
};

/**
 * Capitalizes the first character of the input string.
 *
 * @param {string} value - The input string to be capitalized.
 * @return {string} - The input string with the first character capitalized.
 */
export const capitalize = value => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Formats a date using the moment library.
 *
 * @param {string|Date} date - The date to be formatted.
 * @param {string} format - The format string to use.
 * @return {string} - The formatted date string.
 */
export const formatDate = (date, format = DATE_FORMAT) => (date ? moment(date).format(format) : "-");

/**
 * Converts the HTML content of a specified selector to a Base64-encoded PNG image.
 *
 * @param {string} selector - The CSS selector of the HTML element to convert.
 * @param {Object} options - The options to pass to the html2canvas function.
 * @returns {Promise<string>} A promise that resolves to a Base64-encoded PNG image.
 * @throws Will log an error message if the conversion fails.
 */
export const convertHtmlToBase64 = async (selector, options) => {
  try {
    const generatedCanvas = await html2canvas(document.querySelector(selector), options);
    return generatedCanvas.toDataURL("image/png");
  } catch (error) {
    DupontLogger("error", "Error in converting system diagram to base64", error);
  }
};

/**
 * Combines multiple class names into a single string.
 *
 * @param {...string} classes - The class names to combine.
 * @returns {string} A single string with all the class names combined, separated by spaces.
 */
export const combineClassNames = (...classes) => classes.filter(Boolean).join(" ").trim();

/**
 * Checks if a value is null or undefined.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} True if the value is null or undefined, false otherwise.
 */
export const isNullOrUndefined = value => value === null || value === undefined;

export const isProdEnv = () => process.env.REACT_APP_PROJECT_ENV?.toUpperCase() === "PROD";

export const isDevEnv = () => process.env.REACT_APP_PROJECT_ENV?.toUpperCase() === "DEV";

/**
 * Downloads the provided data as a file with the specified file name.
 *
 * This function creates a Blob from the provided data, generates a URL for the Blob,
 * creates an anchor element, sets its href to the Blob URL, and triggers a download
 * by programmatically clicking the anchor element. After a short delay, it removes
 * the anchor element and revokes the Blob URL to free up memory.
 *
 * @param {BlobPart} data - The data to be downloaded.
 * @param {string} fileName - The name of the file to be downloaded.
 */
export const downloadBlobData = (data, fileName) => {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const aTag = document.createElement("a");
  aTag.href = url;
  aTag.download = fileName;
  document.body.appendChild(aTag);
  aTag.click();
  setTimeout(() => {
    aTag.remove();
    window.URL.revokeObjectURL(url);
  }, 100);
};

/**
 * Swaps the positions of two elements in an array and updates their displayOrder properties.
 *
 * @param {Array} cases - The array of case objects.
 * @param {number} index1 - The index of the first element to swap.
 * @param {number} index2 - The index of the second element to swap.
 * @returns {Array} - A new array with the elements at index1 and index2 swapped and their displayOrder properties updated.
 */
export const swapData = (cases, index1, index2) => {
  const newCases = cases.map(caseItem => ({ ...caseItem }));
  [newCases[index1], newCases[index2]] = [newCases[index2], newCases[index1]];

  // Update displayOrder
  const tempDisplayOrder = newCases[index1].displayOrder;
  newCases[index1].displayOrder = newCases[index2].displayOrder;
  newCases[index2].displayOrder = tempDisplayOrder;

  return newCases;
};

/**
 * Memoizes a given function by caching its results.
 *
 * @param {Function} fn - The function to be memoized.
 * @returns {Function} - A new function that caches the results of the original function.
 *
 * The returned function takes any number of arguments and checks if the result for those arguments
 * is already in the cache. If it is, it returns the cached result. If not, it calls the original
 * function with the arguments, stores the result in the cache, and then returns the result.
 */
export const memoizedFun = fn => {
  const cache = {};
  return (...args) => {
    const key = args.toString();
    if (cache[key]) {
      return cache[key];
    }
    const result = fn(...args);
    cache[key] = result;
    return result;
  };
};

export const isEmptyObject = obj => Object.keys(obj).length === 0;

export const isSelectedTreatment = (treatmentName, systemDesignCaseTreatmentVM) =>
  systemDesignCaseTreatmentVM?.find(item => item.treatmentName === treatmentName);

export const truncateText = (text, length) => {
  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};

/**
 * Truncates a floating-point number to a specified number of decimal places.
 *
 * @param {number} number - The number to be truncated.
 * @param {number} [decimalPlaces=2] - The number of decimal places to keep. Defaults to 2.
 * @returns {number} - The truncated number.
 *
 * e.g it will truncate 5.239999999 => 5.23, when decimalPlaces is 2 and 5.239, when it is 3
 */
export const truncateFloat = (number, decimalPlaces = 2) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.floor(number * factor) / factor;
};

export const getFileExtension = fileName => fileName.split(".").pop();

export const isValuesZero = (...values) => values.every(value => parseFloat(value) === 0);

export const isChargeBalApproxZero = chargeBal => {
  if (chargeBal === undefined) return false;

  const num = Math.abs(Number(chargeBal));

  return num <= CHARGE_BALANCE_THRESHOLD;
};

/**
 * Converts a snake_case string to camelCase.
 *
 * @param {string} str - The snake_case string to convert.
 * @return {string} - The converted camelCase string.
 */
export const snakeToCamelCase = (str = "") => str.replace(/(_\w)/g, matches => matches[1].toUpperCase());

export const injectValues = (str, value) => {
  Object.entries(value).forEach(([key, val]) => {
    const replaceStr = "${" + key + "}";
    str = str.replace(replaceStr, val);
  });
  return str;
};

export const getTrimedTextInLowerCase = text => text?.toLowerCase().trim();

export const getSupportedTechnology = technologyName => {
  if (technologyName === "" || technologyName === undefined) return null;
  return technologyName.split(",");
};

export const isArrayEqual = (a, b) => {
  if (a === null || a == undefined || b === null || b === undefined) return false;
  if (a?.length !== b?.length) return false;
  const tempArr1 = Array.isArray(a) ? [...a] : [];
  const tempArr2 = Array.isArray(b) ? [...b] : [];
  tempArr1.sort();
  tempArr2.sort();
  for (let i = 0; i < tempArr1.length; i++) {
    if (tempArr1[i] !== tempArr2[i]) return false;
  }
  return true;
};

/**
 * Returns a human-readable time difference based on the current time.
 *
 * @param {string|Date|moment.Moment} time - The time to compare with the current time.
 * @returns {string} - A human-readable time difference.
 */
export const getTimeDifference = (time, formatter) => {
  const now = moment();
  time = formatter(time, false);
  const diffInMinutes = now.diff(moment(time), "minutes");
  const diffInHours = now.diff(moment(time), "hours");
  const diffInDays = now.diff(moment(time), "days");

  if (diffInMinutes < 1) {
    return DateString.justNow;
  } else if (diffInMinutes < 60) {
    return diffInMinutes > 1
      ? DateString.minutesAgo.replace("${time}", diffInMinutes)
      : DateString.minuteAgo.replace("${time}", diffInMinutes);
  } else if (diffInHours < 24) {
    return diffInHours > 1
      ? DateString.hoursAgo.replace("${time}", diffInHours)
      : DateString.hourAgo.replace("${time}", diffInHours);
  } else if (diffInDays < 2) {
    return DateString.dayAgo.replace("${time}", 1);
  } else {
    return formatter ? formatter(time) : moment(time).format("YYYY-MM-DD HH:mm:ss");
  }
};

export const isIterable = obj => obj != null && typeof obj[Symbol.iterator] === "function";
