const BASE_UF = "uf/api/v1";
const BASE_MASTER_DATA = "masterdata/api/v1";
const BASE_USER_PROFILE = "userprofile/api/v1";
const SSO_BASE = process.env.REACT_APP_TOKEN_SFDCURL;

export const SSO_URLs = {
  ssoToken: `${SSO_BASE}oauth2/token`,
  ssoAuthorize: `${SSO_BASE}oauth2/authorize`,
  signOutUrl: `${SSO_BASE}auth/idp/oidc/logout`,
};

export const EXTERNAL_URLS = {
  dupontUrl: "https://www.dupont.com/",
  waveProURL: "https://wavepro.dupont.com",
  privacyUrl: "https://www.dupont.com/privacy.html",
  a11yUrl: "https://www.dupont.com/accessibility.html",
  contactDupontUrl: "https://www.dupont.com/water/contact-us.html",
  engagementURL: "https://www.dupont.com/dupont/submitForm?orgId=WS",
  resourceCenterUrl: "https://www.dupont.com/resource-center.html?BU=water",
  termsConditions: "https://www.dupont.com/legal-notices-and-terms-of-use.html",
  ufConfigurationHelp: "https://watersolutions.showpad.com/share/mQ7eHe3T8KitIs6g9dzAp",
  carouselLink: "https://www.dupont.com/water/resources/wave-pro-design-software.html",
  ultrafiltrationURL: "https://www.dupont.com/water/technologies/ultrafiltration-uf.html",
  youtubeURL: "https://www.youtube.com/channel/UCWVgaECFNjMWDczgCTI4pFw",
  instagramURl:
    "https://www.instagram.com/accounts/login/?next=https%3A%2f%2fwww.instagram.com%2fdupontwatersolutions%2f&is_from_rle",
  linkedinURL: "https://www.linkedin.com/showcase/dupont-water-solutions",
  digitalTermsConditions: "https://water-protection.dupont.com/digital-program-terms-and-conditions-of-use.html",
};

const MASTER_DATA = {
  pumps: `${BASE_MASTER_DATA}/Pumps`,
  cases: `${BASE_MASTER_DATA}/Cases`,
  project: `${BASE_MASTER_DATA}/Projects`,
  caseMgmt: `${BASE_MASTER_DATA}/CaseMgmt`,
  feedWater: `${BASE_MASTER_DATA}/FeedWater`,
  folderList: `${BASE_MASTER_DATA}/FolderList`,
  countriesList: `${BASE_MASTER_DATA}/Country`,
  allProjects: `${BASE_MASTER_DATA}/ProjectAll`,
  projectInfo: `${BASE_MASTER_DATA}/ProjectInfo`,
  projectState: `${BASE_MASTER_DATA}/ProjectState`,
  systemDesign: `${BASE_MASTER_DATA}/SystemDesign`,
  projectStatus: `${BASE_MASTER_DATA}/EntityCheck`,
  subTechnology: `${BASE_MASTER_DATA}/SubTechnology`,
  marketSegment: `${BASE_MASTER_DATA}/MarketSegment`,
  unitOfMeasure: `${BASE_MASTER_DATA}/UnitOfMeassure`,
  recentProjects: `${BASE_MASTER_DATA}/ProjectRecent`,
  defaultCurrency: `${BASE_MASTER_DATA}/DefaultCurrency`,
  projectFavorite: `${BASE_MASTER_DATA}/ProjectFavorite`,
  duplicateProject: `${BASE_MASTER_DATA}/DuplicateProject`,
  unitConversionData: `${BASE_MASTER_DATA}/UnitConversion`,
  chemicalCategory: `${BASE_MASTER_DATA}/ChemicalCategory`,
  defaultTechnology: `${BASE_MASTER_DATA}/DefaultTechnology`,
  applicationVersion: `${BASE_MASTER_DATA}/ApplicationVersion`,
  chemicalAdjustment: `${BASE_MASTER_DATA}/ChemicalAdjustment`,
  notifications: `${BASE_MASTER_DATA}/Notifications`,
  projectFolderMapping: `${BASE_MASTER_DATA}/ProjectFolderIDMapping`,
  calculateChemicalAdjustment: `${BASE_MASTER_DATA}/CalculateChemicalAdjustment`,
  calculateFeedWaterData: `${BASE_MASTER_DATA}/CalculateFeedWaterData`,
  folder: `${BASE_MASTER_DATA}/Folder`,
  deleteFolder: `${BASE_MASTER_DATA}/DeleteFolder`,
  restoreFolder: `${BASE_MASTER_DATA}/RestoreFolder`,
  permanentDeleteFolder: `${BASE_MASTER_DATA}/PermanentDeleteFolder`,
  restoreProject: `${BASE_MASTER_DATA}/RestoreProject`,
  folderProject: `${BASE_MASTER_DATA}/FolderProject`,
  projectDeleted: `${BASE_MASTER_DATA}/ProjectDeleted`,
  renameProject: `${BASE_MASTER_DATA}/RenameProject`,
  favoriteProject: `${BASE_MASTER_DATA}/FavoriteProject`,
  projectNumber: `${BASE_MASTER_DATA}/ProjectNumber`,
  projectAll: `${BASE_MASTER_DATA}/ProjectAll`,
  openProjects: `${BASE_MASTER_DATA}/OpenProject`,
  state: `${BASE_MASTER_DATA}/State`,
  sendProject: `${BASE_MASTER_DATA}/SendProject`,
  projAlredaySentTo: `${BASE_MASTER_DATA}/ProjAlredaySentTo`,
  allUsersForSend: `${BASE_MASTER_DATA}/AllUsersForSend`,
  shareProject: `${BASE_MASTER_DATA}/ShareProject`,
  shareProjectLocked: `${BASE_MASTER_DATA}/ShareProject/Locked`,
  shareProjectHistory: `${BASE_MASTER_DATA}/ShareProject/History`,
  shareProjectCollaborators: `${BASE_MASTER_DATA}/ShareProject/Collaborators`,
  shareProjectLockUnlock: `${BASE_MASTER_DATA}/ShareProject/LockUnlock`,
  shareProjectTransferOwnership: `${BASE_MASTER_DATA}/ShareProject/OwnershipTransfer`,
};

const USER_PROFILE = {
  userData: `${BASE_USER_PROFILE}/UserData`,
  ixdUsers: `${BASE_USER_PROFILE}/IXDUsers`,
  importProject: `${BASE_USER_PROFILE}/Import`,
  exportProject: `${BASE_USER_PROFILE}/Export`,
  firstLogin: `${BASE_USER_PROFILE}/FirstLogin`,
  waterLibrary: `${BASE_USER_PROFILE}/WaterLibrary`,
  chemicalLibrary: `${BASE_USER_PROFILE}/ChemicalLibrary`,
  projectCosts: `${BASE_USER_PROFILE}/ChemicalLibrary/ProjectCosts`,
  chemicalLibraryCategory: `${BASE_USER_PROFILE}/ChemicalLibrary/Categories`,
  chemicalLibraryChemicalUsage: `${BASE_USER_PROFILE}/ChemicalLibrary/ChemicalUsage`,
  defaultChemicalPriceAndUtilityCost: `${BASE_USER_PROFILE}/ChemicalLibrary/ProjectCosts/MakeDefault`,
  userInformation: `${BASE_USER_PROFILE}/UserInformation`,
  exampleProject: `${BASE_USER_PROFILE}/ExampleProject`,
  digitalTerms: `${BASE_USER_PROFILE}/DigitalTerms`,
};

const UF = {
  ufConfig: `${BASE_UF}/UFConfig`,
  ufDetails: `${BASE_UF}/UFDetails`,
  designData: `${BASE_UF}/DesignData`,
  ufCalcReport: `${BASE_UF}/UFCalcReport`,
};

const API_URLS = { ...MASTER_DATA, ...USER_PROFILE, ...UF };

export default API_URLS;
