// Public and restricted can be used in RO and other technologies

export const WATER_TYPE_FILTERS = [
  { category: "water", label: "Drinking Water", value: "drinking_water" },
  { category: "water", label: "Non-drinking Water", value: "non_drinking_water" },
];

const RACK_TYPE_FILTERS = [
  { category: "rack", label: "Rack", value: "rack" },
  { category: "rack", label: "Open Platform", value: "open_platform" },
];

export const DEFAULT_PRODUCT_TYPE_FILTERS = [...RACK_TYPE_FILTERS, ...WATER_TYPE_FILTERS];

export const PRODUCT_CATEGORIES = [
  ...DEFAULT_PRODUCT_TYPE_FILTERS,
  { category: "obsolete", label: "Obsolete", value: "obsolete" },
  { category: "chinese", label: "China", value: "chinese" },
];