import axios from "axios";

import { setApiData, setApiError } from "@common/ApiSlice";

import { store } from "../app/store"; // Adjust the import path as necessary

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
});

const ERROR_EXCLUDED_API = ["ShareProject"];

/**
 * Intercepts the response from the axios instance to handle errors globally.
 * If an error response is received, it dispatches an action to update the global state 
 * with the error information.
 * Finally, it rejects the promise with the error.
 */
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    const endpoint = response.config.url;
    const isURLExcluded = ERROR_EXCLUDED_API.some(api => endpoint.includes(api));
    if (response && !isURLExcluded) {
      const apiError = response;
      store.dispatch(setApiError(apiError));
    }
    return Promise.reject(error);
  }
);
//The config must be as per axios request-config, refer https://www.npmjs.com/package/axios#request-config
const getRequestConfig = (method, endpoint, reqParams, reqBody, additionalConfig) => {
  const { headers: additionalHeaders } = additionalConfig;

  return {
    method,
    url: endpoint,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store?.getState().Auth?.idToken}`,
      ...additionalHeaders,
    },
    params: reqParams,
    data: reqBody,
  };
};

/**
 * Makes an HTTP request using the Axios instance.
 *
 * @param {string} endpoint - The API endpoint to call.
 * @param {string} method - The HTTP method to use (GET, POST, PUT, DELETE, PATCH).
 * @param {object} data - The request payload or query parameters.
 * @param {object} additionalConfig - Additional configuration options, such as headers and cacheKey.
 * @returns {Promise} - A promise that resolves to the response data.
 */
// base request function do not use this function directly
const request = async (endpoint, method, reqBody, reqParams, additionalConfig = {}) => {
  const { cacheKey, preferCache } = additionalConfig;
  if (cacheKey && preferCache) {
    const cachedData = store.getState().apiData[cacheKey];
    if (cachedData) {
      return Promise.resolve({ response: { data: cachedData } });
    }
  }
  const config = getRequestConfig(method, endpoint, reqParams, reqBody, additionalConfig);
  const response = await axiosInstance(config);
  if (cacheKey) {
    store.dispatch(setApiData({ key: cacheKey, data: response.data }));
  }
  return response;

};

export const getRequest = (endpoint, reqParams = {}, additionalConfig) =>
  request(endpoint, "GET", undefined, reqParams, additionalConfig);

export const postRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "POST", reqBody, reqParams, additionalConfig);

export const putRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "PUT", reqBody, reqParams, additionalConfig);

export const deleteRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "DELETE", reqBody, reqParams, additionalConfig);

export const patchRequest = (endpoint, reqBody, reqParams = {}, additionalConfig) =>
  request(endpoint, "PATCH", reqBody, reqParams, additionalConfig);
